// carousel
import { tns } from "tiny-slider/src/tiny-slider";
import "tiny-slider/dist/tiny-slider.css";

//CAROUSEL OPEN
var carouselSS= document.getElementById("ss_slider");
if (typeof carouselSS != "undefined" && carouselSS != null) {

  let ss_sliderS = tns({
    container: "#ss_slider",
    items:1,
    slideBy: "page",
    autoplay: false,
    mouseDrag: true,
    gutter: 40,
    speed: 400,
    lazyload: true,
    nav: false,
    controls: true,
    controlsText: ["", ""]
  });
}